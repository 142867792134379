@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Rubik:wght@300;400;600;700&family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Roboto+Mono:wght@100;200;300;400;500;600&family=Rubik:wght@300;400;600;700&family=Ubuntu:wght@300;400;500;700&display=swap');

:root {
  --main-bg-color: rgb(32 32 35);
  --main-font-color: rgb(255 255 255 / 92%);
  --second-font-color: #64ffda;
  --main-font-family: "Calibre", "Inter", "San Francisco", "SF Pro Text", -apple-system, system-ui, sans-serif;
  --second-font-family: 'Roboto Mono', monospace !important;
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --header-color: #20202380;
  --button-hover-bg-color: rgb(100 255 218 / 10%);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-family: 'Rubik', sans-serif;
  font-family: 'Ubuntu', sans-serif;
  background: var(--main-bg-color);
  color: var(--main-font-color);
  scroll-behavior: smooth;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

#root {
  width: 80rem;
  margin: 0 auto;
}

.full_website {
  height: 100vh !important;
  overflow: hidden !important;
}

ul {
  padding: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
p {
  margin: 0;
}

p {
  word-spacing: 1px;
  color: var(--main-font-color);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.canvsView {
  margin: 0 auto;
  width: 600px;
  height: 450px;
  cursor: grabbing;
  margin-top: 58px;
}

.fullCan {
  display: flex;
}

.abouts_section {
  width: 100%;
  margin: 0px auto;
  margin-top: 262px;
}

.header {
  position: fixed;
  right: 0;
  left: 0;
  top: 0px;
  display: flex;
  justify-content: space-between;
  padding: 27px 50px;
  z-index: 2;
  background: var(--header-color);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  width: 86rem;
  margin: 0 auto;
}

.logo_side a {
  font-size: 18px;
  font-weight: bold;
  color: var(--second-font-color);
}

.nav_side li {
  display: inline-block;
  margin: 0px 5px;
  position: relative;
  counter-increment: item 1;
  font-size: 13px;
}

.nav_side li a {
  padding: 10px;
  transition: var(--transition);
}

.nav_side li a:hover {
  color: var(--second-font-color);
}

.nav_side li a:focus {
  border: 2px dashed var(--second-font-color);
}

.nav_side a span {
  color: var(--second-font-color);
  font-family: var(--second-font-family);
  margin-right: 3px;
}

.nav_side .resume_link a {
  padding: 0px;
  color: var(--second-font-color);
  margin-left: 15px;
  border: 1px solid var(--second-font-color);
  border-radius: 4px;
  padding: 0.75rem 1rem;
  line-height: 1;
}

.nav_side .resume_link a:hover {
  background: var(--button-hover-bg-color);
}

.about_section {
  padding-bottom: 40px;
  width: 70%;
  margin: 0 auto;
}

.its_me {
  font-size: 17px;
  background: rgb(255 255 255 / 8%);
  width: 75%;
  margin: 5px auto;
  padding: 17px 20px;
  border-radius: 4px;
  text-align: center;
}

.about_me {
  font-size: 27px;
  position: relative;
  margin: 50px 0px 40px;
  white-space: nowrap;
  font-weight: 600;
  display: flex;
}

.about_me span {
  font-family: var(--second-font-family);
  color: var(--second-font-color);
}

.featured_project_text {
  font-family: var(--second-font-family);
  color: var(--second-font-color);
  margin: 10px 0px;
  font-size: 13px;
}

.name {
  font-size: 2.25rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 10px;
}

.me_description {
  margin-top: 20px;
  line-height: 1.7;
  word-spacing: 2px;
}

.i_build {
  font-size: clamp(40px, 2vw, 30px);
  text-align: center;
  margin-top: 10px;
  font-weight: 600;
}

.i_build span {
  color: var(--second-font-color);
  font-family: var(--second-font-family);
  word-spacing: -5px;
  font-weight: bolder;
}

.social_icons {
  margin-top: 23px;
}

.social_icons a {
  margin-right: 15px;
}

.loading {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden
}

.loading-bar-container {
  width: 100px;
  height: 32px;
  background: #272727;
}

.loading-bar {
  height: 32px;
  background: #f15946;
}

.loading-text {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

.hidden_nav {
  display: none;
}

.ham-box {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;
}

.ham-box-close {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 24px;
}

.ham-box-inner {
  position: absolute;
  top: 45%;
  right: 0px;
  width: 26px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--second-font-color);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(0deg);
}

.ham-box-inner2 {
  position: absolute;
  top: 17%;
  right: 0px;
  width: 31px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--second-font-color);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(0deg);
}

.ham-box-inner3 {
  position: absolute;
  top: 73%;
  right: 0px;
  width: 21px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--second-font-color);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(0deg);
}

.hidden_nav_button {
  background: transparent;
  border: none;
  padding: 10px 14px;
  position: relative;
  z-index: 3;
}

.hidden_nav_button:focus {
  border: 2px dashed var(--second-font-color);
}

.ham-box-inner-close {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--second-font-color);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(137deg);
}

.ham-box-inner2-close {
  position: absolute;
  top: 50%;
  right: 0px;
  width: 30px;
  height: 2px;
  border-radius: 4px;
  background-color: var(--second-font-color);
  transition: transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19) 0s;
  transform: rotate(-137deg);
}

.hiddenbuttonPadd {
  position: fixed;
  background: var(--main-bg-color);
  height: 100vh;
  width: 60vw;
  right: 0;
  top: 0;
  transition: .7s;
  right: -100%;
}

.hiddenbuttonPaddOpen {
  position: fixed;
  background: var(--main-bg-color);
  height: 100vh;
  width: 60vw;
  right: 0;
  top: 0;
  transition: .7s;
  right: 0%;
}

.hiddenbuttonPaddMain {
  width: 60%;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}

.hiddenbuttonPaddMain li a {
  display: inline-block;
  text-decoration: none;
  text-decoration-skip-ink: auto;
  color: inherit;
  position: relative;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 3px 20px 20px;
  font-size: clamp(14px, 4vw, 18px);
}

.hiddenbuttonPaddMain li a span {
  color: var(--second-font-color);
  font-family: var(--second-font-family);
  margin-right: 3px;
}

.hiddenbuttonPaddMain .resume_link a {
  padding: 0px;
  color: var(--second-font-color);
  margin-left: 15px;
  border: 1px solid var(--second-font-color);
  border-radius: 4px;
  padding: 0.90rem 1rem;
  line-height: 1;
  margin-top: 20px;
}

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  /* mobile webkit */
}

.about_detail_image {
  display: flex;
}

.about_details {
  width: 70%;
  padding-right: 35px;
}


.about_image {
  width: 30%;
  position: relative;
  top: 43px;
  z-index: 1;
}

.about_image::after {
  content: "";
  border: 2px solid var(--second-font-color);
  top: 20px;
  left: 20px;
  z-index: -1;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: var(--transition);
}

.skillsets {
  margin-top: 20px;
}

.skillsetUP {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 335px;
  margin-top: 21px;
}

.skillsetUP li {
  position: relative;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: var(--second-font-family);
}

.skillsetUP li::before {
  content: "▹";
  left: 0px;
  color: var(--second-font-color);
  font-size: 12px;
  line-height: 12px;
  margin-right: 10px;
}

.experience_section {
  margin: 60px auto;
  width: 60%;
  padding: 40px 0px;
}

.experience_block {
  display: flex;
  align-items: center;
}


.experience_block_button {
  width: 25%;
  padding-right: 15px;
  border-left: 2px solid #434344;
  position: relative;
  height: fit-content;
}

.stickIndicator {
  height: 40px;
  width: 2px;
  background: var(--second-font-color);
  position: absolute;
  left: -2px;
  transition: var(--transition);
}

.experience_block_button button {
  width: 100%;
  height: 40px;
  font-size: 14px;
  border-radius: 5px;
  color: var(--main-font-color);
  border: none;
  text-align: left;
  font-family: var(--second-font-family);
  padding-left: 18px;
  background: transparent;
  cursor: pointer;
  transition: var(--transition);
}

.experience_block_button button:hover {
  background: #282b30;
  color: var(--second-font-color);
}


.experience_block_inner {
  width: 75%;
  margin-left: 10px;
}

.experience_block_button button.activeTab {
  background: #282b30;
  color: var(--second-font-color);
}

.closeDesBox {
  display: none;
}

.openDesBox {
  display: block;
}

#desSkill {
  line-height: 1.7;
  word-spacing: 2px;
}

.desTitle {
  font-size: 22px;
  margin-bottom: 20px;
}

.desTitle span {
  color: var(--second-font-color);
  font-weight: 800;
}

.works_section {
  margin: 60px auto;
  padding: 40px 0px;
  width: 80%;
}

.works {}


.work {
  margin-bottom: 100px;
  position: relative;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  -webkit-box-align: center;
  align-items: center;
}

.project-image {
  grid-column: 1 / 8;
  grid-area: 1 / 6 / -1 / -1;
  position: relative;
  z-index: 1;
  /* Default z-index */
  transform: scale(1);
  /* Default scale */
  transition: transform 0.3s ease;
  /* Transition effect for transform */
  display: inline-block;
  cursor: pointer;
}

.project-image:hover {
  z-index: 3;
  transform: scale(1.1);
  /* Scale up on hover */
}

.project-description {
  grid-column: 7 / -1;
  text-align: right;
  position: relative;
  grid-area: 1 / 1 / -1 / 7;
}


.file_github_diret_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_project {
  color: rgb(255 255 255 / 92%);
  font-size: 24px;
  font-weight: 700;
  transition: .3s;
  margin: 0px 0px 25px;
}

.description_text {
  box-shadow: 0 10px 30px -15px var(--navy-shadow);
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: relative;
  z-index: 2;
  padding: 25px;
  border-radius: 4px;
  background: hsl(240deg 2.97% 19.8%);
  font-size: 18px;
  line-height: 1.4;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.exe_project {
  color: #c9c9c9;
  font-size: 15px;
  line-height: 1.5;
  word-spacing: 2px;
}

.cats {
  margin-top: 22px;
  margin-bottom: 11px;
}

.ct {
  display: inline-flex;
  font-size: 13px;
  margin-right: 10px;
  color: #dcdcdc;
  font-family: var(--second-font-family);
}

.work:hover {
  /* cursor: pointer; */
}

.work:hover>.title_project {
  color: var(--second-font-color);
}

.workDetail {
  margin: 0 auto;
  width: 70%;
  padding-bottom: 70px;
}

.workdesBreadcrum {
  display: flex;
}

.to-work {
  margin: 0 10px;
}

.current_page_name {
  color: var(--second-font-color);
}

.current_page_project_name {
  font-size: 19px;
  position: relative;
  top: -2px;
  font-weight: 700;
}

.stack_blocks {
  margin-top: 22px;
}

.stack_block {
  display: flex;
  margin-bottom: 7px;
}

.which_platform {
  font-size: 14px;
  background: var(--second-font-color);
  color: black;
  padding: 4px;
  font-weight: 600;
}

.type_platform {
  margin-left: 11px;
}

.type_platform_a {
  color: lightskyblue;
}

.type_platform_a:hover {
  border-bottom: 1px solid lightskyblue;
}

.work_detail_des {
  margin-top: 50px;
  font-size: 18px;
  line-height: 1.4;
}


.work_detail_des ul {
  padding-left: 20px;
}

.work_detail_des ul li {
  list-style-type: circle
}

.work_detail_des img {
  width: 100%;
  margin: 0 auto;
  display: block;
  height: auto;
  margin-top: 20px;
  object-fit: contain;
}

.images__work {
  width: 80%;
  margin: 0 auto;
  display: grid;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.images__work img {
  width: 100%;
  height: 450px;
  margin-top: 0px;
}

.footer_section {
  margin-bottom: 40px;
}

.footer_section p {
  text-align: center;
  font-size: 15px;
  margin: 0 auto;
  width: 90%;
}

.login_message {
  margin: 0 auto;
  width: 63%;
  font-size: 17px;
  text-align: center;
  margin-top: 20px;
  line-height: 1.4;
  color: rgb(255 255 255 / 92%);
}

.email-link {
  margin: 0 auto;
  display: block;
  text-align: center;
  margin-top: 45px;
  border: 1px solid var(--second-font-color);
  width: 114px;
  padding: 14px 0;
  color: var(--second-font-color);
  border-radius: 4px;
  transition: .5s;
}

.email-link:hover {
  background: var(--button-hover-bg-color);
}

.dKThta svg:hover {
  color: #64ffda;
  fill: #64ffda;
}